/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { useNavigate, Link } from "react-router-dom";
import { ADD_HELP_DESK_FAQ, EDIT_HELP_DESK_FAQ } from "../Shared/constant";
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { Table } from 'react-bootstrap';
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonDateTime from "../Common/CommonDateTime";
import CommonewModel from "../Common/CommonewModel";

const HelpDeskFAQ = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { HelpDeskFAQList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[5]?.Edit || PermissionsAccess?.DealerApp[5]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "help_desk_subcategory_list",
                "request" : { 
                    "status" : true, 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : "",
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[5]?.Edit || PermissionsAccess?.DealerApp[5]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "help_desk_faq_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "sub_category_id": "",
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_HELP_DESK_FAQ);
    };

    // SHOW ANSWER OF FAQ
    const ViewAnswer = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "FAQ Answer",
            modalType: "not form FAQ",
            description: `<div class="faq_wrapper">
                <label>${data?.question}</label>
                <p>${data?.answer}</p>
            </div>`,
            button: "",
        });
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // GET SUB CATEGORY NAME
    const GetSubCategoryName = (sub_category_id) => {
        const CurrentSubCategory = HelpDeskFAQList?.sub_category?.filter((elm) => elm?.id === sub_category_id)[0];
        return CurrentSubCategory?.sub_category_name;
    };

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Question"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[5]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Desk FAQ",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />

        <div className="tableView">
            <Scrollbars 
                style={{ height: HelpDeskFAQList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th style={{ width: "30%" }}>Question</th>
                            <th>Sub Category Name</th>
                            <th>Created At</th>
                            <th style={{ width: "15%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {HelpDeskFAQList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, HelpDeskFAQList?.pagination?.current_page, HelpDeskFAQList?.pagination?.record_limit)}
                                </td>
                                <td dangerouslySetInnerHTML={{ __html: elm?.question }}></td>
                                <td>{GetSubCategoryName(elm?.sub_category_id)}</td>
                                <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                <td>
                                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                                        <button 
                                            className="btn view_more_btn"
                                            onClick={() => ViewAnswer(elm)}
                                            aria-controls="example-collapse-text"
                                        >
                                            <i className="bi bi bi-eye"></i> Answer
                                        </button>
                                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[5]?.Edit) && (<td>
                                            <Link className="btn edittable" to={`${EDIT_HELP_DESK_FAQ}/${elm?.id}`}>
                                                <i className="bi bi-pencil-square"></i>
                                            </Link>
                                        </td>)}
                                    </div>
                                </td>
                            </tr>)
                        })}
                        {HelpDeskFAQList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="5">Help Desk FAQ list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {HelpDeskFAQList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={HelpDeskFAQList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>     
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}   
    </section>)
}

export default HelpDeskFAQ;