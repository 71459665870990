/* eslint-disable */
import React, { useContext } from "react";
import GetCurrentTableSRNo from "../GetCurrentTableSRNo";
import { Button, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AFTER_SALES_ORDERS_URL } from "../../Shared/constant";
import CommonDateTime from "../CommonDateTime";
import { TimeSense } from "../TimeSense";
import { toast } from "react-toastify";
import ErpAfsRetryAPI from "../../../Api/ErpAfsRetryAPI";
import CommonLogout from "../CommonLogout";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import AvatarSet from "../AvatarSet";

const TableBody = (props) => {
    const { srNumber, tabName, OrderList, RowData, 
        Pagination, EditAccess, OrderNewModel, 
        setOrderNewModel, ActionColumn, setLoader,
        showModalNew, setShowModalNew, APIcalling
    } = props;
    const { accessToken, device_id } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    
    const Dispatch = useDispatch();

    // ORDER LOGS
    const showOrder = (value, url) => {
        let current_url = url === "AFS_ORDERS" ? "AFS Order Log" : "Order Log";

        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: current_url,
            subtitle: "",
            Data: value
        });
    };

    // INVOICE DETAILS
    const callInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = { 
            "transmit": "broadcast", 
            "url": "afs_order_detail",
            "request" : { 
                "order_id" : code,
                "is_weight" : true
            },
            "DeviceId" : device_id,
            modal: true
        };
        wsSend_request(websocket, param);
    };

    // GET FRANCHISE NAME
    const GetFranchiseName = (billing_id) => {
        const current = OrderList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    // Retry ERP order
    const RtryFunction = async (data, url) => {        
        if(url === "invoice") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Get ERP Invoice",
                subtitle: "Get ERP Invoice",
                modalType: "form",
                button: "Create",
                Data: data,
            });
        } else {
            CommonAPICall(data);
        };
    };

    // API CALLING FUCNTION
    const CommonAPICall = async (data) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const response = await ErpAfsRetryAPI(formData, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            APIcalling();
            toast.success(response?.message);
        } else {
            setLoader(false);
            APIcalling();
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };


    // COMMON BUTTON FUNCTION
    const CommonButton = ({ data }) => {
        return(<td>
            {ActionColumn?.label === "retry" ? (<Button 
                className="btn edittable edittableretry btn btn-primary" 
                onClick={() => RtryFunction(data, (data?.erp_id === null || data?.erp_id === "") ? "retry" : "invoice")}
            >
                {(data?.erp_id === null || data?.erp_id === "") ? (<>
                    <i className="bi bi-arrow-repeat"></i> Retry
                </>) : (<>
                    <i className="bi bi-receipt"></i> Get Invoice
                </>)}
            </Button>) : (<Link className="btn edittable" to={`${ActionColumn?.naviagte}${data?.id}`}>
                <i className="bi bi-pencil-square"></i>
            </Link>)}
        </td>)
    };

    let CurrentColspan = RowData?.length + 1;

    if(EditAccess && ActionColumn?.isShow){
        CurrentColspan += 1;
    };

    // get name
    const GetName = (id, url) => {
        return OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0]?.[url?.label];
    };

    return(<tbody>
        {OrderList?.list?.map((item, rowIndex) => {
            return(<tr key={rowIndex}>
                <td>
                    {srNumber?.clickable ? (<Button className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(item?.id, srNumber?.events)}>
                        {GetCurrentTableSRNo(rowIndex, Pagination?.current_page, Pagination?.record_limit)}
                    </Button>) : GetCurrentTableSRNo(rowIndex, Pagination?.current_page, Pagination?.record_limit)}
                </td>
                {RowData?.map((row, ind) => {
                    if(row?.type === "navigate") {
                        return(<td key={ind}>
                            {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${AFTER_SALES_ORDERS_URL}/${item?.id}`}>
                                <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                            </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                        </td>)
                    } else if(row?.type === "date") {
                        return(<td key={ind}>
                            {CommonDateTime(item[row?.key], "LL", "show")}
                        </td>)
                    } else if(row?.type === "label_get_from_franchise") {
                        return(<td key={ind}>
                            {GetFranchiseName(item[row?.key])?.name}
                        </td>)
                    } else if(row?.type === "label_get_from_list") {
                        return(<td key={ind}>
                            {GetName(item[row?.key], row?.filterFrom)}
                        </td>)
                    } else if(row?.type === "image") {
                        return(<td key={ind}>
                            <AvatarSet
                                type={"product"}
                                url={item[row?.key]}
                                alt={"tableimg"}
                            />
                        </td>)
                    } else if(row?.type === "invoice_modal_open_navigate") {
                        return(<td key={ind}>
                            {EditAccess ? (
                                <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, item?.id)}>{item[row?.key] || "-"}</b>
                            ) : (
                                <b className="tableBolanchor" style={{ cursor: "default" }} >{item[row?.key] || "-"}</b>
                            )}
                        </td>)
                    } else if(row?.type === "pending_since") {
                        return(<td key={ind}>
                            {TimeSense(item[row?.key])}
                        </td>)
                    } else if(row?.type === "status") {
                        return(<td key={ind}>
                            {(item[row?.key] === null || item[row?.key] === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                        </td>)
                    } else if(row?.type === "status_active") {
                        return(<td key={ind}>
                            {item[row?.key] ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                        </td>)
                    } else if(row?.type === "hyper_link_shipment_url") {
                        return(<td key={ind}>{
                            item[row?.key] === null ?
                                "-" :
                                item?.shipment_url === null ? item[row?.key] : (<a href={item?.shipment_url} target="__blank">{item[row?.key] ? item[row?.key] : "-"}</a>)
                        } </td>)
                    } else {
                        return(<td key={ind}>{item[row?.key] || "-"}</td>)
                    };
                })}
                {(EditAccess && ActionColumn?.isShow) && (<CommonButton data={item} />)}
            </tr>)
        })}
        {OrderList?.list?.length === 0 && (<tr>
            <td style={{ textAlign: "center" }} colSpan={CurrentColspan}>List not found</td>
        </tr>)}
    </tbody>)
}

export default TableBody;