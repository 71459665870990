import moment from "moment";
import 'moment-timezone';

// common date fucntion
const CommonDateTime = (date, format, method) => {
    if (date !== null) {
        const currentTimezone = moment.tz.guess();
        return moment(date).tz(currentTimezone).format(format);
    } else {
        return method === "show" ? "-" : null;
    };
}

export default CommonDateTime;