/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { HELP_DESK_CATEGORY, HELP_DESK_FAQ, HELP_DESK_SUB_CATEGORY } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select';

const CreateHelpDeskCategory = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ CategoryState, setCategoryState ] = useState({
        CategoryName: "",
        SubCategoryName: "",
        Question: "",
        Answer: "",
        Status: true
    });
    const { ClearFormSet, HelpDeskcategoryList, HelpDeskSubcategoryList, HelpDeskAllcategoryList, AllHelpDeskSubcategoryList, HelpDeskFAQList, selfInfo, device_id } = useSelector((state) => state.adminReducers);
    const optionsDeskCategory = HelpDeskAllcategoryList?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name }));
    const optionsDeskSubCategory = AllHelpDeskSubcategoryList?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name }));
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const { roomId } = useParams();
    let UpdateListing = LocationRoute === "edit-help-desk-category" ? HelpDeskcategoryList : LocationRoute === "edit-help-desk-faq" ? HelpDeskFAQList : HelpDeskSubcategoryList;
    const CurrentData_ = UpdateListing?.list?.filter((elm) => elm?.id === roomId)[0];

    // error state
    const [ errorCategoryName, seterrorCategoryName ] = useState("");
    const [ errorSubCategoryName, seterrorSubCategoryName ] = useState("");
    const [ errorQuestion, seterrorQuestion ] = useState("");
    const [ errorAnswer, seterrorAnswer ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // EDIT INITIAL UPDATE STATE
    useEffect(() => {
        if(LocationRoute === "edit-help-desk-category") {
            setTimeout(() => {
                setCategoryState({
                    ...CategoryState,
                    CategoryName: CurrentData_?.category_name,
                    Status: CurrentData_?.is_active
                });
            }, 500);
        } else if(LocationRoute === "edit-help-desk-sub-category") {
            // setTimeout(() => {
                const CurrentCategory = optionsDeskCategory?.filter((elm) => elm?.value === CurrentData_?.category_id)[0];
                setCategoryState({
                    ...CategoryState,
                    CategoryName: CurrentCategory,
                    SubCategoryName: CurrentData_?.sub_category_name,
                    Status: CurrentData_?.is_active
                });
            // }, 500);
        } else if(LocationRoute === "edit-help-desk-faq") {
            const SubCurrentCategory = optionsDeskSubCategory?.filter((elm) => elm?.value === CurrentData_?.sub_category_id)[0];
            setCategoryState({
                ...CategoryState,
                Question: CurrentData_?.question,
                Answer: CurrentData_?.answer,
                SubCategoryName: SubCurrentCategory,
            });
        };
    }, [ CurrentData_ ]);

    // GET ALL CATEGORY LIST
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[4]?.Edit) {
            if(LocationRoute === "add-help-desk-sub-category" || LocationRoute === "edit-help-desk-sub-category") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "help_desk_category_list",
                    "request" : { 
                        "status" : true, 
                        "limit" : 5000,
                        "page_no" : 1, 
                        "search" : "",
                        "order_by": "-updated_at"
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            };
        };
    }, []);

    // GET ALL SUB CATEGORY LIST
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[5]?.Edit) {
            if(LocationRoute === "add-help-desk-faq" || LocationRoute === "edit-help-desk-faq") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "help_desk_subcategory_list",
                    "request" : { 
                        "status" : true, 
                        "limit" : 5000,
                        "page_no" : 1, 
                        "search" : "",
                        "order_by": "-updated_at"
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        };
    }, []);

    // SELECT CATEGORY
    const CategorySelect = (value) => {
        if(value !== null) {
            setCategoryState({...CategoryState, CategoryName: value});
            CheckValid(value, {type: 'CategoryName', error: seterrorCategoryName});
        } else {
            setCategoryState({...CategoryState, CategoryName: ""});
            CheckValid(value, {type: 'CategoryName', error: seterrorCategoryName});
        }
    };

    // SELECT SUB CATEGORY
    const SubCategorySelect = (value) => {
        if(value !== null) {
            setCategoryState({...CategoryState, SubCategoryName: value});
            CheckValid(value, {type: 'SubCategoryNameSelect', error: seterrorSubCategoryName});
        } else {
            setCategoryState({...CategoryState, SubCategoryName: ""});
            CheckValid(value, {type: 'SubCategoryNameSelect', error: seterrorSubCategoryName});
        }
    };

    // CREATE & UPDATE HELP DESK CATEGORY & SUB CATEGORY 
    const CreateUpdateSubmit = () => {
        if(LocationRoute === "add-help-desk-category" || LocationRoute === "edit-help-desk-category") {
            if(CategoryState?.CategoryName.trim() !== "") {
                if(LocationRoute === "edit-help-desk-category") {
                    Dispatch(ClearFormSetFutios({
                        url: "help_desk_category_edit",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "help_desk_category_edit",
                        "request" : { 
                            "category_id" : CurrentData_?.id,
                            "category_name" : CategoryState?.CategoryName.trim(),
                            "is_active" : CategoryState?.Status
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(ClearFormSetFutios({
                        url: "help_desk_category_add",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "help_desk_category_add",
                        "request" : { 
                            "category_name" : CategoryState?.CategoryName.trim(),
                            "is_active" : CategoryState?.Status
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                }
            } else {
                CheckValid(CategoryState?.CategoryName.trim(), {type: 'CategoryName', error: seterrorCategoryName});
            };
        } else if(LocationRoute === "add-help-desk-sub-category" || LocationRoute === "edit-help-desk-sub-category") {
            if(CategoryState?.SubCategoryName.trim() !== "" && CategoryState?.CategoryName !== "") {
                if(LocationRoute === "edit-help-desk-sub-category") {
                    Dispatch(ClearFormSetFutios({
                        url: "help_desk_subcategory_edit",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "help_desk_subcategory_edit",
                        "request" : { 
                            "sub_category_id" : CurrentData_?.id,
                            "sub_category_name" : CategoryState?.SubCategoryName.trim(),
                            "category_id" : CategoryState?.CategoryName?.value,
                            "is_active" : CategoryState?.Status
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(ClearFormSetFutios({
                        url: "help_desk_subcategory_add",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "help_desk_subcategory_add",
                        "request" : { 
                            "sub_category_name" : CategoryState?.SubCategoryName.trim(),
                            "category_id" : CategoryState?.CategoryName?.value,
                            "is_active" : CategoryState?.Status
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                }
            } else {
                CheckValid(CategoryState?.SubCategoryName.trim(), {type: 'SubCategoryName', error: seterrorSubCategoryName})
                CheckValid(CategoryState?.CategoryName, {type: 'CategoryName', error: seterrorCategoryName});
            };
        } else if(LocationRoute === "add-help-desk-faq" || LocationRoute === "edit-help-desk-faq") {
            if(CategoryState?.Question.trim() !== "" && CategoryState?.Answer.trim() !== "" && CategoryState?.SubCategoryName !== "") {
                if(LocationRoute === "edit-help-desk-faq") {
                    Dispatch(ClearFormSetFutios({
                        url: "help_desk_faq_edit",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "help_desk_faq_edit",
                        "request" : { 
                            "faq_id" : CurrentData_?.id,
                            "question" : CategoryState?.Question?.trim(),
                            "answer" : CategoryState?.Answer?.trim(),
                            "sub_category_id" : CategoryState?.SubCategoryName?.value,
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(ClearFormSetFutios({
                        url: "help_desk_faq_add",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "help_desk_faq_add",
                        "request" : { 
                            "question" : CategoryState?.Question?.trim(),
                            "answer" : CategoryState?.Answer?.trim(),
                            "sub_category_id" : CategoryState?.SubCategoryName?.value,
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                }
            } else {
                CheckValid(CategoryState?.Question.trim(), {type: 'Question', error: seterrorQuestion})
                CheckValid(CategoryState?.Answer.trim(), {type: 'Answer', error: seterrorAnswer})
                CheckValid(CategoryState?.SubCategoryName, {type: 'SubCategoryNameSelect', error: seterrorSubCategoryName});
            };
        };
    };

    // AFTER SUCCESS THE API RESPONSE REDIRECT TO LISTING
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            if(ClearFormSet?.url === "help_desk_category_add" || ClearFormSet?.url === "help_desk_category_edit") {
                Navigate(HELP_DESK_CATEGORY);
            } else if(ClearFormSet?.url === "help_desk_subcategory_edit" || ClearFormSet?.url === "help_desk_subcategory_add") {
                Navigate(HELP_DESK_SUB_CATEGORY);
            } else if(ClearFormSet?.url === "help_desk_faq_add" || ClearFormSet?.url === "help_desk_faq_edit") {
                Navigate(HELP_DESK_FAQ);
            };
        };
    }, [ ClearFormSet ]);

    // BACK URL UPDATE LOCATIONS WISE
    const BackUrl = () => {
        if(LocationRoute === "add-help-desk-category" || LocationRoute === "edit-help-desk-category") {
            return HELP_DESK_CATEGORY;
        } else if(LocationRoute === "add-help-desk-sub-category" || LocationRoute === "edit-help-desk-sub-category") {
            return HELP_DESK_SUB_CATEGORY;
        } else if(LocationRoute === "add-help-desk-faq" || LocationRoute === "edit-help-desk-faq") {
            return HELP_DESK_FAQ;
        };
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={BackUrl()} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    {LocationRoute === "add-help-desk-sub-category" || LocationRoute === "edit-help-desk-sub-category" ? (<React.Fragment>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Sub Category Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={CategoryState?.SubCategoryName} 
                                    onChange={(e) => setCategoryState({...CategoryState, SubCategoryName: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'SubCategoryName', error: seterrorSubCategoryName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Sub Category Name"
                                />
                                {errorSubCategoryName !== "" && (<span className="error_medotory">{errorSubCategoryName}</span>)}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Select Category<span className="mendatory_star">*</span></Form.Label>
                                <div className="selectMultiselect" id="selectMultiselectBrand">
                                    <Select 
                                        value={CategoryState?.CategoryName}
                                        onChange={(e) => CategorySelect(e)} 
                                        isClearable 
                                        options={optionsDeskCategory}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Category"
                                        styles={customStyles}
                                    />
                                    {errorCategoryName !== "" && <span className="error_medotory">{errorCategoryName}</span>}
                                </div>
                            </Form.Group>
                        </div>
                    </React.Fragment>) : LocationRoute === "add-help-desk-faq" || LocationRoute === "edit-help-desk-faq" ? (<React.Fragment>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Question<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    as="textarea"
                                    value={CategoryState?.Question} 
                                    onChange={(e) => setCategoryState({...CategoryState, Question: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Question', error: seterrorQuestion})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Question"
                                />
                                {errorQuestion !== "" && (<span className="error_medotory">{errorQuestion}</span>)}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Answer<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    as="textarea"
                                    value={CategoryState?.Answer} 
                                    onChange={(e) => setCategoryState({...CategoryState, Answer: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Answer', error: seterrorAnswer})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Answer"
                                />
                                {errorAnswer !== "" && (<span className="error_medotory">{errorAnswer}</span>)}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Select Sub Category<span className="mendatory_star">*</span></Form.Label>
                                <div className="selectMultiselect" id="selectMultiselectBrand">
                                    <Select 
                                        value={CategoryState?.SubCategoryName}
                                        onChange={(e) => SubCategorySelect(e)} 
                                        isClearable 
                                        options={optionsDeskSubCategory}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Sub Category"
                                        styles={customStyles}
                                    />
                                    {errorSubCategoryName !== "" && <span className="error_medotory">{errorSubCategoryName}</span>}
                                </div>
                            </Form.Group>
                        </div>
                    </React.Fragment>) : (<div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Category Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={CategoryState?.CategoryName} 
                                onChange={(e) => setCategoryState({...CategoryState, CategoryName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CategoryName', error: seterrorCategoryName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Category Name"
                            />
                            {errorCategoryName !== "" && (<span className="error_medotory">{errorCategoryName}</span>)}
                        </Form.Group>
                    </div>)}
                    {(LocationRoute !== "add-help-desk-faq" && LocationRoute !== "edit-help-desk-faq") && (<div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle
                                    valueToggle={CategoryState?.Status}
                                    setValueToggle={() => setCategoryState({...CategoryState, Status: !CategoryState?.Status})}
                                    name={""}
                                />
                                {CategoryState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>)}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "category_add" || ClearFormSet?.url === "category_edit")}
                            onClick={() => CreateUpdateSubmit()} 
                            variant="primary" 
                        >
                            {(LocationRoute === "edit-help-desk-category" || LocationRoute === "edit-help-desk-sub-category" || LocationRoute === "edit-help-desk-faq") ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default CreateHelpDeskCategory;