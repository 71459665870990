import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import BlackLogo from "../assets/img/em_black_logo.svg";

const Privacypolicy = (props) => {
    return(
    <Scrollbars 
        style={{ height: "calc(100vh)"}} 
        renderView={props => <div {...props} className="view"/>}
        className="ScrollbarsSidebar"
    >
        <div className="privacy_policy_wrapper">
            <div className="heaing_main">
                <img src={BlackLogo} alt="logo" />
                <h2>Privacy Policy</h2>
            </div>
            <div className="privacy_main_body">
            <div className="privyc_header">
                    <h4>Introduction</h4>
                    <p>We are committed to safeguarding the privacy of our Application users. This Privacy Policy explains how we collect, store, use, and protect your personal information. By using this Application, you consent to the practices described in this policy.</p>
                </div>
                <div className="privyc_body">
                    <h4>Data Collection</h4>
                    <p>We may collect, store, and use the following types of personal and application-related data:</p>
                    <ul>
                        <li>Device Information: Details about your device, including IP address, operating system, browser type, and application usage metrics.</li>
                        <li>Account Information: Data you provide during account creation, such as name, email address, and contact details.</li>
                        <li>Warranty Registration Information: Information submitted for the purpose of registering product warranties, including product details and purchase dates.</li>
                        <li>Order and Inventory Data: Details related to your orders, inventory, and transactions processed through the app.</li>
                        <li>Helpdesk and Feedback Submissions: Any information shared via tickets, inquiries, or feedback forms.</li>
                        <li>Usage Data: Analytics to understand how users interact with the application to improve functionality and user experience.</li>
                    </ul>
                </div>
                <div className="privyc_body">
                    <h4>Using Your Personal Data</h4>
                    <p>Your personal data will only be used for purposes relevant to your interaction with the Application, including but not limited to:</p>
                    <ul>
                        <li>Administering warranties and managing product registrations.</li>
                        <li>Providing a seamless experience for managing orders, inventory, and accounts.</li>
                        <li>Sending notifications related to your orders, tickets, and other app features.</li>
                        <li>Offering support through our helpdesk for inquiries, complaints, or issues.</li>
                        <li>Improving application performance and personalizing your user experience.</li>
                    </ul>
                </div>
                <div className="privyc_body">
                    <h4>Data Protection</h4>
                    <p>We prioritize the security of your personal data and take appropriate technical and organizational measures to prevent its loss, misuse, or unauthorized alteration. These measures include:</p>
                    <ul>
                        <li>Secure servers and encrypted data transmission protocols.</li>
                        <li>Regular audits to ensure compliance with relevant data protection regulations, including GDPR and local laws.</li>
                        <li>Restricting access to personal data to authorized personnel only.</li>
                    </ul>
                </div>
                <div className="privyc_body">
                    <h4>Data Retention</h4>
                    <p>Your personal data will be retained only for as long as necessary to fulfill the purposes for which it was collected or to comply with applicable legal, regulatory, or internal policy requirements.</p>
                </div>
                <div className="privyc_body">
                    <h4>Your Rights</h4>
                    <p>You have the following rights concerning your personal data:</p>
                    <ul>
                        <li>Access: Request access to the personal data we hold about you.</li>
                        <li>Correction: Request correction of any inaccurate or incomplete data.</li>
                        <li>Deletion: Request the deletion of your personal data, subject to legal or contractual obligations.</li>
                        <li>Objection: Object to the processing of your data for specific purposes.</li>
                        <li>Portability: Request a copy of your personal data in a structured, commonly used, and machine-readable format.</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us using the details provided at the end of this policy.</p>
                </div>
                <div className="privyc_body">
                    <h4>Third-Party Data Sharing</h4>
                    <p>We do not sell, distribute, or lease your personal data to third parties. We will only share your data:</p>
                    <ul>
                        <li>With your explicit consent.</li>
                        <li>If required by law or legal authorities.</li>
                        <li>With affiliates or subsidiaries for internal operational purposes.</li>
                    </ul>
                    <p>Your data will never be shared with third-party marketers or external vendors for promotional purposes.</p>
                </div>
                <div className="privyc_body">
                    <h4>Cross-border Data Transfers</h4>
                    <p>If personal data needs to be transferred outside your country of residence, we ensure 
                        <br/ ><br />
                        it is protected under applicable laws and regulations. Any data shared via the Application may be accessible worldwide, and we cannot prevent its use by unauthorized parties.
                    </p>
                </div>
                <div className="privyc_body">
                    <h4>Updates to This Policy</h4>
                    <p>This Privacy Policy may be updated periodically to reflect changes in our practices or legal obligations. Any updates will be posted on this page, and we encourage users to review it regularly.
                    </p>
                </div>
                <div className="privyc_body">
                    <h4>Contact</h4>
                    <p>If you have any questions about this Privacy Policy or how we handle your personal data, please contact us at:
                        [contactus@emotorad.com]
                    </p>
                </div>  
            </div>
        </div>
    </Scrollbars>)
}

export default Privacypolicy;