/* eslint-disable */
import React, { useState } from "react";
import { Table } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import OrderStatusModel from "../OrderStatusModel";
import CommonPagination from "../CommonPagination";
import CommonLoader from "../CommonLoader";
import CommonewModel from "../CommonewModel";

const CommonTable = (props) => {
    const { style, srNumber, tabName, isActionColumnAvailable, TableColumns, OrderList, Pagination, EditAccess, PaginationCurrentPage, PaginationFunction, APIcalling } = props;
    const [ Loader, setLoader ] = useState(false);
    const [ OrderNewModel, setOrderNewModel ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: "",
        Data: {},
    });

    return(<div className="tableView gstreporttableadded">
        <Scrollbars
            // style={{ height: Pagination?.total_records > 10 ? "calc(100vh - 290px)" : "calc(100vh - 255px)" }}
            style={style}
            renderView={props => <div {...props} className="view" />}
            className="ScrollbarsSidebar"
        >
            <Table bordered>
                <TableHead 
                    Columns={TableColumns} 
                    ActionColumn={isActionColumnAvailable}
                />
                <TableBody 
                    srNumber={srNumber}
                    OrderList={OrderList}
                    RowData={TableColumns}
                    EditAccess={EditAccess}
                    Pagination={Pagination}
                    OrderNewModel={OrderNewModel}
                    setOrderNewModel={setOrderNewModel}
                    ActionColumn={isActionColumnAvailable}
                    tabName={tabName}
                    setLoader={setLoader}
                    showModalNew={showModalNew}
                    setShowModalNew={setShowModalNew}
                    APIcalling={APIcalling}
                />
            </Table>
        </Scrollbars>

        {/* PAGINATION */}
        {Pagination?.total_records > 10 && (<CommonPagination
            currentPage={PaginationCurrentPage}
            paginationLength={Pagination}
            currentFunction={PaginationFunction}
        />)}

        {/* ORDER LOG MODAL */}
        {OrderNewModel?.open && (<OrderStatusModel 
            setOrderNewModel={setOrderNewModel} 
            OrderNewModel={OrderNewModel} 
        />)}

        {/* COMMON MODAL FOR INVOICE CREATIONS */}
        {showModalNew?.open && (<CommonewModel 
            setShowModalNew={setShowModalNew} 
            showModalNew={showModalNew} />
        )}

        {/* COMMON LOADER */}
        {Loader && (<CommonLoader />)}
    </div>)
};

export default CommonTable;