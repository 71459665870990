/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal, Form, Table } from 'react-bootstrap';
import { apiMapping } from "../../APIpermission";

const CommonPermissionModel = (props) => {
    const { showModalNew, setShowModalNew, modules, setModules, ApplicationState } = props;

    const [ CurrentPermissions, setCurrentPermissions ] = useState({});

    const handleClose = () => {
        setShowModalNew({ ...setShowModalNew, open: false });
    };

    useEffect(() => {
        setCurrentPermissions(modules);
    }, [ modules ])

    // dashbaord checkbox
    const DashBoardMainCheckboxChange = (e, url) => {
        const Check = e?.target?.checked;
        setCurrentPermissions({
            ...CurrentPermissions, 
            Dashboard: {
                ...CurrentPermissions.Dashboard, 
                [url]: Check
            },
        });
    };
    
    // checkbox with permissions
    const MainCheckboxChange = (e, label, type) => {
        setCurrentPermissions({
            ...CurrentPermissions, 
            ProductMasters: CurrentPermissions?.ProductMasters?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Store: CurrentPermissions?.Store?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Warehouse: CurrentPermissions?.Warehouse?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Masters: CurrentPermissions?.Masters?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            AdminTools: CurrentPermissions?.AdminTools?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Masters: CurrentPermissions?.Masters?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            CRMTools: CurrentPermissions?.CRMTools?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            ReSells: CurrentPermissions?.ReSells?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            DealerApp: CurrentPermissions?.DealerApp?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            OrderManagement: CurrentPermissions?.OrderManagement?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            PaymentManager: CurrentPermissions?.PaymentManager?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            DealerAppOnly: CurrentPermissions?.DealerAppOnly?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
        });
    };

    const AddPermissions = () => {
        setModules(CurrentPermissions);
        handleClose();
    };

    // Admin Tools Disable
    const AdminToolsDisable = (label) => {
        if(ApplicationState?.UserType === "warehouse_manager") {
            if(label === "Rm Quick Login") {
                return true;
            } else if(label === "Users") {
                return true;
            } else if(label === "Account Ledger Report") {
                return true;
            } else if(label === "Debit Credit Notes") {
                return true;
            } else if(label === "Debit Credit Notes Report") {
                return true;
            } else if(label === "Payment Approval") {
                return true;
            } else if(label === "Gst Data Report") {
                return true;
            } else if(label === "Warehouse Quick Login") {
                return true;
            } else if(label === "Erp Outstanding Report") {
                return true;
            } else if(label === "Incentive Slab" || label === "Schemes") {
                return true;
            } else {
                return false;
            };
        } else if(ApplicationState?.UserType === "regional_sales_team") {
            if(label === "Warehouse Quick Login") {
                return true;
            } else if(label === "Rm Quick Login") {
                return true;
            } else if(label === "Incentive Slab" || label === "Schemes") {
                return true;
            } else if(label === "After Sales Orders") {
                return true;
            } else {
                return false;
            };
        } else if(ApplicationState?.UserType === "sales_operations" || ApplicationState?.UserType === "inventory_operations" || ApplicationState?.UserType === "logistics_ops" || ApplicationState?.UserType === "accounts" || ApplicationState?.UserType === "regional_sales_team" || ApplicationState?.UserType === "inside_sales" || ApplicationState?.UserType === "ecommerce") {
            if(label === "Incentive Slab" || label === "Schemes") {
                return true;
            } 
        } else if(ApplicationState?.UserType === "after_sales_and_service") {
            if(label === "So Creations (AFS)" || label === "Other Order (AFS)") {
                return false;
            } else {
                return true;
            };
        } else {
            return false;
        };
    };

    // Get Admin id update
    const GetAdminToolsUpdateId = (label) => {
        if(ApplicationState?.UserType === "warehouse_manager") {
            if(label === "Rm Quick Login" || label === "Users" || label === "Account Ledger Report" || label === "Debit Credit Notes" || label === "Debit Credit Notes Report" || label === "Payment Approval" || label === "Gst Data Report" || label === "Warehouse Quick Login" || label === "Erp Outstanding Report") {
                return "productMastersRMSMWH";
            } else if(label === "Incentive Slab") {
                return "productMastersRMSMWH";
            } else if(label === "Schemes") {
                return "productMastersRMSMWH";
            } else {
                return "productMasters";
            };
        } else if(ApplicationState?.UserType === "regional_sales_team") {
            if(label === "Warehouse Quick Login") {
                return "productMastersRMSMWH";
            } else if(label === "Rm Quick Login") {
                return "productMastersRMSMWH";
            } else if(label === "Incentive Slab") {
                return "productMastersRMSMWH";
            } else if(label === "Schemes") {
                return "productMastersRMSMWH";
            }else {
                return "productMasters";
            };
        } else if(ApplicationState?.UserType !== "admin") {
            if(label === "Incentive Slab") {
                return "productMastersRMSMWH";
            } else if(label === "Schemes") {
                return "productMastersRMSMWH";
            } else {
                return "productMasters";
            };
        } else {
            return "productMasters";
        };
    };

    const [ clearAllTitle, setclearAllTitle ] = useState("Clear All");

    // clear all permissions functions
    const clearAllPermissions = () => {
        if(clearAllTitle === "Clear All") {
            setCurrentPermissions({
                ...CurrentPermissions,
                InventoryActive: false,
                ProductMastersActive: false,
                Dashboard: {
                    SalesRevenueReport: false,
                    DealerPerformance: false,
                    OrderFulfillment: false,
                    DailyOrdersRGLKolkata: false,
                    DailyOrdersRGLDelhi: false,
                    DailyOrdersRGLBangalore: false,
                    AllOrderSummery: false,
                },
                ProductMasters: [
                    {
                        label: "Product Applications",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Category",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Sub Category",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Product Group",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Product Creation",
                        Edit: false,
                        View: false,
                        API: ""
                    }
                ],
                StoreActive: false,
                StockDetailsReportSendMailActive: false, 
                Store: [
                    {
                        label: "Stock Inward",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Stock Level",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Stock Details",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Stock Inward Log",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Stock Details Report",
                        Edit: false,
                        View: false,
                        API: ""
                    }
                ],
                WarehouseActive: false,
                Warehouse: [
                    {
                        label: "Warehouse",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Warehouse Quick Login",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Dispatch List",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                ],
                AdminToolsActive: false,
                AdminTools: [
                    {
                        label: "Users",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Create Franchise",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Franchise Type Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Rm Quick Login",
                        Edit: false,
                        View: false,
                        API: ""
                    }
                ],
                Masters: [
                    {
                        label: "States",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Cities",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Pincodes",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Box",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Couriers",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                ],
                CRMToolsActive: false,
                CRMTools: [
                    {
                        label: "Region Master",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Purchase List",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Sales Person Performance",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Purchase Log List",
                        Edit: false,
                        View: false,
                        API: ""
                    }
                ],
                ReSellsActive: false,
                ReSells: [
                    {
                        label: "Resellcycle Reports",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Resale Brands",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Settings",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Age Value",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Point List",
                        Edit: false,
                        View: false,
                        API: ""
                    }
                ],
                DealerAppActive: false,
                DealerApp: [
                    {
                        label: "Incentive Slab",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Schemes",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Tickets",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Help Desk Category",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Help Desk Sub Category",
                        Edit: false,
                        View: false,
                        API: ""
                    }
                ],
                OrderManagementActive: false,
                OrderManagement: [
                    {
                        label: "All Orders Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "SO Creation",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "SO Approvals",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Warehouse Approvals",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Pending For Accounts",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Pending For Stores",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Pending For Dispatch",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Pending For Delivery",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Order Delivered",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Order Rejected",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Order Cancelled",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "E-Invoice Details",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "E-Invoice Cancel",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "E-Way Bill Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Return And Replace List",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Erp Api Failures",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "FBA Orders",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Re Assign warehouse",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "After Sales Orders",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Unicommerce Api Failures",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "So Creations (AFS)",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Other Order (AFS)",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                ],
                PaymentManagerActive: false,
                PaymentManager: [
                    {
                        label: "Account Ledger Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Debit Credit Notes",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Debit Credit Notes Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Payment Approval",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Gst Data Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                    {
                        label: "Erp Outstanding Report",
                        Edit: false,
                        View: false,
                        API: ""
                    },
                ],
            });
            setclearAllTitle("Undo")
        } else {
            setCurrentPermissions(modules);
            setclearAllTitle("Clear All")
        };
    };

    // reorder management flow
    function reorderArray(arr) {
        if(arr !== undefined) {
            const topOrder = ["After Sales Orders"];
            const afterSOCreation = ["So Creations (AFS)", "Other Order (AFS)"];
            
            // Find the index of "SO Creation"
            const soCreationIndex = arr.findIndex(item => item.label === "SO Creation");
        
            // Filter out the items to be reordered
            const topItems = arr.filter(item => topOrder.includes(item.label));
            const afterSOItems = arr.filter(item => afterSOCreation.includes(item.label));
            const remainingItems = arr.filter(item => 
                !topOrder.includes(item.label) && 
                !afterSOCreation.includes(item.label)
            );
        
            // Insert "After Sales Orders" at the top
            const reorderedArray = [...topItems, ...remainingItems];
        
            // Insert "So Creations (AFS)" and "Other Order (AFS)" after "SO Creation"
            if (soCreationIndex !== -1) {
                reorderedArray.splice(soCreationIndex + 2, 0, ...afterSOItems);
            }
        
            return reorderedArray;
        };
    };
    
    return (<Modal
        show={showModalNew?.open}
        onHide={handleClose}
        className="commonModal permissionmodel"
    >
        <Modal.Header closeButton>
            <Modal.Title style={{ width: showModalNew?.title === "Are you sure?" ? "100%" : "" }}>
                {showModalNew?.title} <div className="usertyperPermission">{ApplicationState?.UserType?.replaceAll("_", " ")}</div>
                <button className={clearAllTitle === "Clear All" ? "btn btn-danger clearall_permissions_btn" : "btn btn-primary clearall_permissions_btn"} onClick={() => clearAllPermissions()}>
                    <i className={clearAllTitle === "Clear All" ? "bi bi-x-circle" : "bi-arrow-counterclockwise"}></i> {clearAllTitle}
                </button>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body id={ApplicationState?.UserType === "after_sales_and_service" ? "after_sales_and_service_menu" : ""} style={{ paddingTop: "10px" }}>
            <div className="row">
                <div className="col-md-3">
                    <div className="row">
                        <h5 >Dashboard</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Dashboard</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sales Revenue Report</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.SalesRevenueReport}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "SalesRevenueReport")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Dealer Performance</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.DealerPerformance}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "DealerPerformance")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Order Fulfillment</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.OrderFulfillment}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "OrderFulfillment")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Daily Orders RGL Kolkata</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.DailyOrdersRGLKolkata}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "DailyOrdersRGLKolkata")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Daily Orders RGL Delhi</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.DailyOrdersRGLDelhi}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "DailyOrdersRGLDelhi")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Daily Orders RGL Bangalore</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.DailyOrdersRGLBangalore}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "DailyOrdersRGLBangalore")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>All Order Summary</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.AllOrderSummery}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "AllOrderSummery")}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="row">
                        <h5 >Inventory</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Product Masters</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.ProductMasters?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Stores</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.Store?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                    <tr>
                                        <td>Stock Details Report Send Mail</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions.StockDetailsReportSendMailActive}
                                                onChange={(e) => { 
                                                    setCurrentPermissions({
                                                        ...CurrentPermissions, 
                                                        StockDetailsReportSendMailActive: e.target.checked,
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td>
                                            -
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Warehouse</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.Warehouse?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <h5 >Admin Tools</h5>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Admin Tools</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.AdminTools?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Masters</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.Masters?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <h5 >Order Management</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Order Management</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reorderArray(CurrentPermissions?.OrderManagement)?.filter((elz) => {
                                        if(elz?.label !== "Pending For Accounts") {
                                            return elz;
                                        };
                                    })?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label === "Unicommerce Api Failures" ? "Unicommerce Order Sync" : elm?.label === "Erp Api Failures" ? "ERP Order Sync" : elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={(elm?.label === "So Creations (AFS)" || elm?.label ===  "Other Order (AFS)") ? "productMastersss" : "productMasters"}
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={elm?.label !== "After Sales Orders" && AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={(elm?.label === "So Creations (AFS)" || elm?.label ===  "Other Order (AFS)" || elm?.label === "After Sales Orders") ? "productMastersss" : "productMasters"}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || elm?.label !== "After Sales Orders" && AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Payment Manager</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.PaymentManager?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <h5 >CRM Tools</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>CRM Tools</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.CRMTools?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Re-Sale</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.ReSells?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className={ApplicationState?.UserType !== "admin" ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Dealer App</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.DealerApp?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    {ApplicationState?.UserType === "sale_franchise_person" && (<div className="mt-3">
                        <div className={ApplicationState?.UserType !== "admin" ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>Dealer App Only</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.DealerAppOnly?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>)}
                </div>
            </div>
            <button className="btn permissionbtn" onClick={() => AddPermissions()}><i className="bi bi-person-check"></i> Add Permission</button>
        </Modal.Body>
    </Modal>)
}

export default CommonPermissionModel;