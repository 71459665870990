import { SEND_MAIL_WITH_PDF_UNICOMMERCE_API_URL } from "../components/Shared/constant";

const CommonRestQueryParamAPI = async (accessToken, params, url) => {
    let api_url = "";
    if(url === "send_mail_pdf_unicommerce_api_call") {
        api_url = SEND_MAIL_WITH_PDF_UNICOMMERCE_API_URL;
    };
    const responce = window.axios.get(`${api_url}${params}`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':`Bearer ${accessToken}`
          },
      }).then(function (result) {
          return {
            ...result.data,
            status:200
          };
      }).catch(function (result) {
          return {
            ...result?.response?.data,
            status:result?.response?.status
          }
      });
      return responce;
};
  
export default CommonRestQueryParamAPI;