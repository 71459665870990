import React from "react";
import { Badge } from "react-bootstrap";

const TicketsCommonStatus = (status) => {
    if(status === "Failed") {
        return (<Badge bg="danger" className="status_mark">Failed</Badge>);
    } else if(status === "Open" || status === "OPEN") {
        return (<Badge bg="warning" className="status_mark in_progress">Open</Badge>)
    } else if(status === "On Hold") {
        return (<Badge bg="info" className="status_mark yet_to_start">Onhold</Badge>)
    } else if(status === "Escalated") {
        return (<Badge bg="success" className="status_mark is_escalated">Escalated</Badge>)
    } else if(status === "Dispatched") {
        return (<Badge bg="" className="status_mark is_dispached">Dispatched</Badge>)
    } else if(status === "Delivered") {
        return (<Badge bg="primary" className="status_mark ">Delivered</Badge>)
    } else {
        return (<Badge bg="secondary" className="status_mark is_closed">Closed</Badge>)
    };
};

export default TicketsCommonStatus;