/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { StartMainCommonLoader, TicketsVerfiyAPI } from "../../redux/actions/adminActions";

const TicketsVerify = (props) => {
    const { ticket_number, MainState, setMainState, error_tickets_number, seterror_tickets_number, showModalNew, setShowModalNew } = props;
    const { websocket } = useContext(WebSocketContext);
    const { device_id, tickets_response, commonMainLoader } = useSelector((state) => state.adminReducers);
    const dispatch = useDispatch();
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    // ENTER SEARCH TICKETS 
    const onEnterInvoiceSearch = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            VerifyTickets();
        };
    };

    // VERIFY TICKTES API CALLING FUNCTION
    const VerifyTickets = async () => {
        if(ticket_number !== "") {
            dispatch(StartMainCommonLoader({
                url: "ticket_search_by_ticket_number",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "ticket_search_by_ticket_number",
                "request" : { 
                    "ticket_number" : ticket_number, 
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            CheckValid(ticket_number, {type: 'ticket_number', error: seterror_tickets_number})
        };
    };

    useEffect(() => {
        if(Object?.keys(tickets_response)?.length > 0) {
            seterror_tickets_number("");
            if(tickets_response?.status === "SUCCESS") {
                setShowModalNew({
                    ...showModalNew,
                    open: open,
                    title: "Ticket Verification Successful !",
                    subtitle: "Ticket-Verify",
                    description: "",
                    modalType: "Ticket-Verify",
                    button: "",
                    Data: tickets_response
                });
            } else {
                setShowModalNew({
                    ...showModalNew,
                    open: open,
                    title: "Ticket Verification Fail",
                    subtitle: "Ticket-Verify",
                    description: "",
                    modalType: "Ticket-Verify",
                    button: "Confirm",
                    Data: tickets_response
                });
            };
        };
    }, [ tickets_response ]);

    // RESET VERIFIED TICKETS DATA
    const ResetVerifyTickets = () => {
        dispatch(StartMainCommonLoader({
            url: "",
            loader: false
        }));
        setMainState({
            ...MainState,
            ticket_number: "",
        });
        dispatch(TicketsVerfiyAPI({}));
    };

    const handleKeyPress = (e) => {
        // Allow only digits (0-9)
        const charCode = e.which || e.keyCode;
        if (charCode < 48 || charCode > 57) {
          e.preventDefault();
        };
    };

    return (<div className="col-md-4">
            <Form.Group className="mb-3 " >
                <Form.Label>Ticket Number<span className="mendatory_star">*</span></Form.Label>
                <div className="verify_tickets_wrapper">
                    <div className="verify_wrapper">
                        <Form.Control 
                            type="number" 
                            value={ticket_number} 
                            onChange={(e) => setMainState({...MainState, ticket_number: e.target.value})}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'ticket_number', error: seterror_tickets_number})}
                            onKeyDown={(e) => {
                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                EmptySpaceFieldValid(e);
                                onEnterInvoiceSearch(e);
                            }}
                            onKeyPress={handleKeyPress}
                            className="inputBoxs"
                            disabled={(commonMainLoader?.url === "ticket_search_by_ticket_number" && commonMainLoader?.loader) || tickets_response?.status === "SUCCESS"}
                            placeholder="Enter Ticket Number" 
                            maxLength={3}
                            min="1" 
                            max="10"
                            // step="any"
                        />
                        {(commonMainLoader?.url === "ticket_search_by_ticket_number" && commonMainLoader?.loader) && (<div className="verify_loader">
                            <Spinner />
                        </div>)}
                    </div>
                    <div className="verify_tickets_wrapper_btns">
                        <Button className="btn verify_tickets_btn" onClick={() => VerifyTickets()} disabled={(commonMainLoader?.url === "ticket_search_by_ticket_number" && commonMainLoader?.loader) || tickets_response?.status === "SUCCESS"} >
                            Verify
                        </Button>
                        <Button className="btn reset_btn" onClick={() => ResetVerifyTickets()} >
                            Reset
                        </Button>
                    </div>
                </div>
                {error_tickets_number !== "" && (<span className="error_medotory">{error_tickets_number}</span>)}
            </Form.Group>
        </div>
    );
};

export default TicketsVerify;